<script lang="ts">
	import { YouTubeLite as YouTubeLiteComponent } from '@samsveltecomponents/youtube-lite';
	interface Props {
		id: string;
		title?: string;
	}

	let { id, title = 'YouTube Video' }: Props = $props();
</script>

<YouTubeLiteComponent {id} {title} />
