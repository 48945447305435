<script lang="ts">
	import SvgIcon from '@samsveltecomponents/core/Icons/SvgIcon.svelte';
	import { soundcloud, youtube, githubLogo } from '@samsveltecomponents/core/Icons/icons';
	import MdiInstagram from '~icons/mdi/instagram';
	import MdiTwitter from '~icons/mdi/twitter';
	import AlgoliaAutocomplete from './AlgoliaAutocomplete/AlgoliaAutocomplete.svelte';

	const year = new Date().getFullYear();

	const menu_items = [
		{ title: 'Home', href: '/' },
		{ title: 'About', href: '/about' },
		{ title: 'Works', href: '/works' },
		{ title: 'Events', href: '/events' },
		{ title: 'Contact', href: '/contact' }
		// { title: 'Writing', href: '/writing' }
	];
</script>

<footer class="footer col-start-1 col-end-4 mt-12 -mb-32 grid grid-cols-main-layout">
	<div class="col-start-2 mx-[-12px] h-[3px] bg-[color:var(--header-bottom-line-color)]"></div>
	<div class="footer-first col-start-2">
		<div class="col-start-1">
			<a data-sveltekit-preload-data class="text-link underline" href="/contact">contact me</a>
		</div>
		<div id="copyright" class="col-start-1 justify-self-center text-xs">
			© Sam Greening 2019 - {year}
		</div>
		<AlgoliaAutocomplete />
	</div>

	<nav
		class="col-span-3 grid h-32 auto-rows-min grid-cols-main-layout bg-gradient-to-t from-white to-transparent [&>*]:col-start-2"
	>
		<ul class="my-1 grid grid-cols-3 items-center justify-items-center text-sm sm:grid-cols-5">
			{#each menu_items as { title, href }}
				<li class="m-1">
					<a {title} class="text-link block px-2 py-1" {href} data-sveltekit-preload-data>
						{title}
					</a>
				</li>
			{/each}
		</ul>

		<ul class="flex flex-row items-center justify-center [&_svg]:inline-block">
			<li class="mx-2">
				<a
					title="SoundCloud"
					class="text-link block p-1"
					target="_blank"
					rel="noreferrer"
					href="https://soundcloud.com/samgreening"
				>
					<SvgIcon icon={soundcloud} />
				</a>
			</li>
			<li class="mx-2">
				<a
					title="YouTube"
					class="text-link block p-1"
					target="_blank"
					rel="noreferrer"
					href="https://www.youtube.com/channel/UCAXyelE5pk47QjFedXnTDgQ"
				>
					<SvgIcon icon={youtube} />
				</a>
			</li>
			<li class="mx-2">
				<a
					title="GitHub"
					class="text-link block p-1"
					target="_blank"
					rel="noreferrer"
					href="https://github.com/SG60/"
				>
					<SvgIcon icon={githubLogo} />
				</a>
			</li>
			<li class="mx-2">
				<a
					title="Instagram"
					class="text-link block p-1"
					target="_blank"
					rel="noreferrer"
					href="https://www.instagram.com/samgreening"
				>
					<MdiInstagram />
				</a>
			</li>
			<li class="mx-2">
				<a
					title="Twitter"
					class="text-link block p-1"
					target="_blank"
					rel="noreferrer"
					href="https://twitter.com/samjg60"
				>
					<MdiTwitter />
				</a>
			</li>
		</ul>
	</nav>
</footer>

<style lang="scss">
	footer {
		div.footer-first {
			margin: 0.7rem auto;
			width: 100%;
			display: grid;

			// 3 columns equally sized
			grid-auto-columns: 1fr 1fr 1fr;
			justify-items: center;
			justify-content: center;
			align-items: baseline;

			div {
				padding: 0.1rem 0;
			}
		}
	}
</style>
