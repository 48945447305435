import type { HandleClientError } from '@sveltejs/kit';

import * as Sentry from '@sentry/sveltekit';
import { sharedConfig } from '$utils/sentrySharedConfig';

Sentry.init({
	...sharedConfig,

	integrations: [
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false
		})
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// This sets the sample rate at 10%. You may want to change it to 100% while in development
	// and then sample at a lower rate in production:
	replaysSessionSampleRate: 0.1,
	// If you're not already sampling the entire session, change the sample rate to 100% when
	// sampling sessions where errors occur:
	replaysOnErrorSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing headers should be enabled
	// NOTE: This config option might be able to be reset to the default once the new Sentry SDK is released (v8)
	// See: https://github.com/getsentry/sentry-javascript/pull/10621/files#diff-c1603ee6a674bb75255aa448057630506153e363297fd58668e6679f10909bc6
	tracePropagationTargets: ['localhost', /^\//, new RegExp(`^${window.location.origin}/`)]
});

const myErrorHandler: HandleClientError = ({ error, event }) => {
	console.error('An error occurred on the client side:', error, event);
};

export const handleError: HandleClientError = Sentry.handleErrorWithSentry(myErrorHandler);
